import {FhirService} from "../../../resources/services/FhirService";
import {FormBaseClass} from "../../../resources/elements/FormBaseClass";
import {translations} from "../../../resources/classes/translations";
import * as Fhir from "../../../resources/classes/FhirModules/Fhir";
import {CIRiskAssessment, QuestionnaireResponse} from "../../../resources/classes/FhirModules/Fhir";
import {NitTools} from "../../../resources/classes/NursitTools";
import {Prompt} from "../../../resources/elements/prompt";
import {IFormSetting} from "../../../resources/classes/IFormSettings";
import {ConfigService} from "../../../resources/services/ConfigService";
import {QuestionnaireService} from "resources/services/QuestionnaireService";
import {PatientService} from "resources/services/PatientService";
import {fhirEnums} from "../../../resources/classes/fhir-enums";
import {RuntimeInfo} from "../../../resources/classes/RuntimeInfo";
import {PatientItem} from "../../../resources/classes/Patient/PatientItem";

const moment = require("moment");

import HTTPVerb = fhirEnums.HTTPVerb;
import BundleType = fhirEnums.BundleType;
import QuestionnaireResponseStatus = fhirEnums.QuestionnaireResponseStatus;

const environment = require('../../../../config/environment.json');

export class PflegeplanungAnamnese extends FormBaseClass {
    assessment_created: boolean = false;
    assessmentBackup: any;

    async afterLoaded() {
        super.afterLoaded();
        await this.checkForAutoGeneratedResponses();
        await super.recalcRisks(this.patient.latestAssessment, true);
    }

    async createButtonClicked(preset?): Promise<any> {
        return new Promise<any>(async (resolve, reject) => {
            if (!this.patient || this.patient.isOffline) resolve(undefined);
            let hadResponses = this.responses.length > 0;
            let anamnesisSettings: IFormSetting = ConfigService.GetFormSettings("anamnesis");
            // When there is no existing response in this encounter and values should be transferred, which
            // is whether to take over data from prev. encounter ..
            if (anamnesisSettings && !hadResponses &&
                anamnesisSettings.settings &&
                anamnesisSettings.settings.takeOverFromPreviousEncounter &&
                anamnesisSettings.settings.takeOverFromPreviousEncounter.enabled === true) {

                // .. load a list of prior responses from finished encounters ..
                let listUrl = `Encounter?subject=${fhirEnums.ResourceType.patient}/${this.patient.id}&status=finished&_revinclude=QuestionnaireResponse:${FhirService.FhirVersion > 3 ? 'encounter' : 'context'}`;

                let bundle = await this.fhirService.fetch(listUrl);

                // .. get all QuestionnaireResponses from the bundle ..
                let oldResponses = <any[]>bundle.filter(o => o.resourceType === fhirEnums.ResourceType.questionnaireResponse);

                // .. create a dummy patient to save code lines ..
                let p: PatientItem = new PatientItem(this.patient);
                p.questionnaireResponses = oldResponses;

                // .. get the latest response of the current questionnaire type ...
                let oldResponse = QuestionnaireService.GetLatestResponseOfType(p, this.qList.QAnamnesisId, [fhirEnums.QuestionnaireResponseStatus.completed, fhirEnums.QuestionnaireResponseStatus.amended]);

                // no response, so stop checking
                if (oldResponse) {
                    let oldRef = (oldResponse.context||oldResponse.encounter)?.reference;
                    let oldEncounter = oldRef ? <any>await this.fhirService.get(oldRef) : undefined;

                    // .. when everything needed exists ..
                    if (oldEncounter && oldResponse && oldResponse.item && oldResponse.item.length > 0) {
                        // .. create the dialogmessage ..
                        let message = this.i18n.tr("anamnesis_confirm_take_values_from_previous_encounter");

                        // .. get encounter period or "n.a." ..
                        let date = oldEncounter.period ? oldEncounter.period.end || oldEncounter.period.start : undefined;
                        let dateString = this.i18n.tr("n_a");
                        if (date) {
                            dateString = moment(date).format(RuntimeInfo.DateTimeFormat);
                        }

                        // .. get visit-number or "n.a." ..
                        let caseIdentifierString = this.i18n.tr("n_a");
                        let caseIdentifier = oldEncounter.identifier ? oldEncounter.identifier.find(o => o.system && o.system.endsWith("/visitNumbers")) : undefined;
                        if (caseIdentifier && caseIdentifier.value) {
                            caseIdentifierString = caseIdentifier.value;
                        }

                        // .. replace message values ..
                        message = message.replace("%CASE-ID%", caseIdentifierString).replace("%CASE-END%", dateString);

                        // .. and ask the user what to do:
                        // noinspection ES6MissingAwait
                        this.dialogService.open({
                            viewModel: Prompt,
                            model: {
                                message: message,
                                title: translations.translate('confirm'),
                                yesText: translations.translate("yes"),
                                noText: translations.translate('no'),
                                showCancel: false
                            },
                            lock: true
                        })
                            .whenClosed(async dialogResult => {
                                // when the user clicked on "NO" ..
                                if (dialogResult.wasCancelled) {
                                    // .. just create the response and bail out
                                    await super.createButtonClicked(preset);
                                    return
                                }

                                // .. else on YES: create a new QuestionnaireResponse ..

                                let newResponse = Fhir.Tools.SubstituteDefaultQRSkeleton(this.patient, this.questionnaire.id, fhirEnums.QuestionnaireResponseStatus.inProgress);

                                // .. set the current default values:
                                newResponse = QuestionnaireResponse.SetDefaultValues(newResponse, undefined);

                                // .. check if there are fields configured to take over from prior encounter ..
                                // .. iterate through the given fields ..
                                anamnesisSettings.settings.takeOverFromPreviousEncounter.fields.forEach((linkId: string) => {
                                    // .. try to get the answer from previous response ..
                                    let oldItem = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(oldResponse, linkId, false);

                                    // .. if existent in old response and has an answer ..
                                    if (oldItem && oldItem.answer) {
                                        let newItem = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(newResponse, linkId, false);
                                        if (newItem) {    // .. if still existing in new reponse ...
                                            newItem.answer = NitTools.Clone(oldItem.answer); // .. take over the answer into the new item
                                        }
                                    }
                                });

                                // .. and create the item. this is taken from the original function (super.createButtonClicked)
                                this.fhirService.create(newResponse)
                                    .then((result: any) => {
                                        PatientService.AddQuestionnaireResponse(this.patient, result);
                                        let tmp = QuestionnaireService.GetResponsesOfType(this.patient, this.questionnaire.id); //  Fhir.Tools.GetQuestionnaireListForCombo(this.questionnaire.id, this.patient, true);
                                        tmp.forEach(qr => qr.text = {
                                            status: "generated",
                                            div: `<div xmlns="http://www.w3.org/1999/xhtml">${moment(qr.authored).format(RuntimeInfo.DateTimeFormat)}</div>`
                                        });
                                        this.responses = tmp;
                                        this.selectedid = result.id;
                                        this.checkForPrintButton();
                                        this.afterCreate(result);

                                        this.isLoading = false;

                                        // .. don't forget to warn the user to verify the applied data:
                                        this.dialogMessages.prompt(this.i18n.tr("anamnesis_information_values_from_previous_encounter"), this.i18n.tr("information"), false);
                                    })
                                    .catch(error => {
                                        const msg = error.message || error.response || error;
                                        console.warn(msg);
                                        reject(msg);
                                    });
                            });
                    } else {
                        resolve(await super.createButtonClicked(preset));
                    }
                } else {
                    // .. there is already an existing entry, so just create a new response
                    resolve(await super.createButtonClicked(preset));
                }
            } else if (anamnesisSettings) {
                resolve(await super.createButtonClicked(preset));
            }
        });
        // create a new RiskAssessment
    }

    async afterCreate(result: any) {
        super.afterCreate(result);
        this.updateSempaBirthField(this.patient);
        if (this.patient.latestAssessment) {
            // update the state of the latest RiskAssessment
            CIRiskAssessment.annulateExistingRiskAssessments(this.patient, this.fhirService, this.patient.latestAssessment, result)
                .catch(e => {
                    console.warn(e);
                })
                .finally(() => {
                    this.isLoading = false;
                });
        }
    }

    async attached(): Promise<void> {
        if (ConfigService.Debug)
            window["anamnesis"] = this;

        this.buttonText = translations.translate("new_anamnesis");
        this.route = ConfigService.FormNames.Anamnesis;

        await super.attached(); // goes to FormBaseClass
    }

    async afterLoadedPatient(pat) {
        // this is the anamnesis, but we have to load the assessments too
        if (pat) {
            let setting = ConfigService.GetFormSettings('assessment');
            if (setting) {
                const questionnaire = QuestionnaireService.GetQuestionnaireByNameDirect(setting.questionnaireName);
                if (questionnaire) {
                    let  url = `QuestionnaireResponse?questionnaire=${QuestionnaireService.GetQuestionnaireQueryUrl(questionnaire)}`+
                                        `&${FhirService.FhirVersion >= 4 ? 'encounter' : 'context'}=Encounter/${pat.encounterId}`;

                    const qrs = <any[]>await this.fhirService.fetch(url);
                    qrs.forEach(q => PatientService.AddQuestionnaireResponse(this.patient, q, true));
                }
            }

        }
        await super.afterLoadedPatient(pat);
    }

    async afterSave(response: any): Promise<any> {
        this.isLoading = true;

        let cfg = await ConfigService.LoadConfigOverride(this.patient?.ward, this.patient);
        const anamnesisFormSettings: IFormSetting = cfg.forms.find(o => o.route === 'anamnesis');
        const assessmentFormSetting: IFormSetting = cfg.forms.find(o => o.route === 'assessment');
        const assessmentQuestionnaire: any = QuestionnaireService.GetQuestionnaireByNameDirect(this.patient.getAssessmentName());

        if (this.patient && this.patient.latestAssessment && Fhir.QuestionnaireResponse.ResponseValuesDiffer(this.patient.latestAssessment, this.assessmentBackup)) {
            await this.fhirService.update(this.patient.latestAssessment);
            this.assessmentBackup = NitTools.Clone(this.patient.latestAssessment);
            PatientService.AddQuestionnaireResponse(this.patient, this.patient.latestAssessment);
        }

        PatientService.AddQuestionnaireResponse(this.patient, this.response);
        //let latestAnamnese = QuestionnaireService.GetLatestResponseOfType(this.patient, this.questionnaire.id, [fhirEnums.QuestionnaireResponseStatus.amended, fhirEnums.QuestionnaireResponseStatus.completed]);
        let latestAssessment = QuestionnaireService.GetLatestResponseOfType(this.patient, assessmentQuestionnaire.id, [fhirEnums.QuestionnaireResponseStatus.amended, fhirEnums.QuestionnaireResponseStatus.completed]);

        // when a specific value for iso has been specified and the patient is not in state iso (mark_1=false),
        // toggle that mark_1 to true in flags and additional info
        const mark1 = this.patient.marks.find(o => o.index === 1);
        if (ConfigService.UseIsolationForFlag1 && !mark1.checked) {
            if (['completed', 'amended'].indexOf(this.response.status) > -1 && this.setting && this.setting.settings && this.setting.settings.isolationTrigger) {
                let trigger = this.setting.settings.isolationTrigger;
                if (trigger.linkId && trigger.yesValue) {
                    let answerValue = QuestionnaireResponse.GetResponseItemValueByLinkId(this.response, trigger.linkId, undefined);
                    if (answerValue && answerValue === trigger.yesValue) {

                        // toggle the state of isolation when match found:

                        mark1.checked = true;

                        // ensure patient flags
                        // if (!this.patient.flags) await Fhir.Tools.CreateFlag(this.patient, this.fhirService);
                        if (this.patient.flags && this.patient.flags && this.patient.flags.code && this.patient.flags.code.coding) {
                            let flag = this.patient.flags.code.coding.find(o => o.system.endsWith('mark_1'));
                            if (flag) {
                                flag.code = "true";
                            }
                        }

                        // ensure patient additional info
                        let ai = QuestionnaireService.GetLatestResponseOfType(this.patient, this.qList.QAdditionalInfoId);
                        if (!ai) {
                            try {
                                let newAi = Fhir.Tools.SubstituteDefaultQRSkeleton(this.patient, this.qList.QAdditionalInfoId, 'in-progress');
                                ai = <any>await this.fhirService.create(newAi);
                                if (ConfigService.Debug)
                                    console.debug(`Created new Additional Info for Encounter ${this.patient.encounter?.id}`);
                            } catch (e) {
                                ai = undefined;
                                console.warn(e.message || JSON.stringify(e));
                            }
                        }

                        // set value in additional info:
                        let mark1Item = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(ai, 'mark_1', true);
                        if (mark1Item) {
                            mark1Item.answer = [{valueBoolean: true}];
                        }

                        // update ai and flag
                        await this.analyzeService.validateMarks(this.patient);
                        await this.fhirService.bundle([this.patient.flags, ai], HTTPVerb.put);
                    }
                }
            }
        }

        let volleMobilYesValue = '88_04_02';

        //#region When "volle Sf Mobilität..." is set to true, generate an assessment with default (=good) values
        // all group checkboxes are set to hidden and the number of visible groups should be stored as 0
        let volleSfMobilitaetItem = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(this.response, "88_04", false);

        let createDefaultAssessmentOn: ICreateDefaultAssessmentOn;
        if (cfg && anamnesisFormSettings && anamnesisFormSettings.settings &&
            anamnesisFormSettings.settings["createDefaultAssessmentOn"] &&
            anamnesisFormSettings.settings["createDefaultAssessmentOn"]["field"] && // check if everything ...
            anamnesisFormSettings.settings["createDefaultAssessmentOn"]["value"]) { // ... needed is set (field and value, obviously)
            createDefaultAssessmentOn = anamnesisFormSettings.settings["createDefaultAssessmentOn"];
            volleMobilYesValue = createDefaultAssessmentOn.value;
            volleSfMobilitaetItem = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(this.response, createDefaultAssessmentOn.field, false);
        }

        let createPerfectAssessment = (this.response.status === fhirEnums.QuestionnaireResponseStatus.completed || this.response.status === fhirEnums.QuestionnaireResponseStatus.amended);
        createPerfectAssessment = createPerfectAssessment            // .. and SelfCare full Mobbility is checked ..
            && typeof volleSfMobilitaetItem !== "undefined";
        createPerfectAssessment = createPerfectAssessment // .. and no assessment created in here ..
            && this.assessment_created === false;
        createPerfectAssessment = createPerfectAssessment    // .. and the patient has not already an assessment ..
            && typeof this.patient.latestAssessment === "undefined";

        let isAnalyzed: boolean = false;
        if (createPerfectAssessment) {
            let vollValue = Fhir.QuestionnaireResponse.GetResponseItemValue(volleSfMobilitaetItem);
            // 88_04_02 => JA
            if (vollValue === volleMobilYesValue /* aka YES */) {
                if (ConfigService.Debug)
                    console.debug(`FULL SF Mobility detected, automatically generating assessment`)
                RuntimeInfo.ShowInfo(this.i18n.tr('assessment_will_be_generated'), false);

                const assessmentResponse: any = Fhir.Tools.SubstituteDefaultQRSkeleton(this.patient, assessmentQuestionnaire.id, fhirEnums.QuestionnaireResponseStatus.completed);
                assessmentResponse.authored = new Date().toJSON();
                assessmentResponse.id = NitTools.Uid();

                let kvpArray = Fhir.Questionnaire.GetDefaultQuestionnaireValues(assessmentQuestionnaire);
                kvpArray.forEach(kvp => {
                    let item = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(assessmentResponse, kvp.key, true);
                    Fhir.QuestionnaireResponse.SetResponseItemCoding(item, kvp.value);
                    let questionnaireItem = Fhir.Questionnaire.GetQuestionnaireItemByLinkId(assessmentQuestionnaire, kvp.key);

                    try {
                        // find the correct option if applicable and set the correct text/display values
                        if (questionnaireItem && questionnaireItem.option) {
                            let option = questionnaireItem.option.find(o => o.valueCoding && o.valueCoding.code === kvp.value);
                            if (option && option.valueCoding.display) {
                                let text = option.valueCoding.display;
                                if (text.indexOf('|') > -1) {
                                    text = text.split('|')[1];
                                }

                                item.answer[0].valueCoding.display = text;
                            }
                        }
                    } catch (e) {
                        debugger;
                    }
                })

                // important: don't forget to set the default values into assessment, so that analysis does not fail
                QuestionnaireResponse.SetDefaultValues(assessmentResponse, undefined);

                assessmentQuestionnaire.item.filter(o => o.type === "group").forEach(group => {
                    // finally set the indicator to hide the groups:
                    let ext = Fhir.Tools.GetOrCreateExtension(assessmentResponse, `questionnaire-group-visible/${group.linkId}`, true);
                    ext.valueBoolean = false;
                });

                let groupVisibleItem = Fhir.Tools.GetOrCreateExtension(assessmentResponse, "questionnaire-visible-group-count", true);
                groupVisibleItem.valueInteger = 0;
                assessmentResponse.status = fhirEnums.QuestionnaireResponseStatus.completed;

                const bundleItems: any[] = [assessmentResponse];

                if (!assessmentResponse.extension) assessmentResponse.extension = [];
                // check for automatic generation of Responses in ASSESSMENT
                if (assessmentFormSetting && assessmentFormSetting.autoGenerateNewResponses) {
                    for (const autoSetting of assessmentFormSetting.autoGenerateNewResponses) {
                        if (autoSetting.questionnaire) {
                            const q = QuestionnaireService.GetQuestionnaireByNameDirect(autoSetting.questionnaire);
                            if (q) {
                                const autoResponse = Fhir.Tools.SubstituteDefaultQRSkeleton(this.patient, q.id);
                                autoResponse.id = NitTools.Uid();
                                if (autoSetting.createLinkBetweenResponses) {
                                    QuestionnaireResponse.SetAttachedResponse(this.patient, autoResponse, assessmentResponse);
                                    QuestionnaireResponse.SetAttachedResponse(this.patient, assessmentResponse, autoResponse);
                                }

                                PatientService.AddQuestionnaireResponse(this.patient, autoResponse, true);
                                bundleItems.push(autoResponse);
                            }
                        }
                    }
                }

                // update flags resource:
                if (this.patient.flags) {
                    const latestAssessmentDateFlagSystem = `latest${assessmentQuestionnaire.name}Date`;
                    let latestAssessmentFlag: any = Fhir.Tools.GetOrCreateFlag(this.patient.flags, latestAssessmentDateFlagSystem, true);
                    if (latestAssessmentFlag) latestAssessmentFlag.code = assessmentResponse.authored;

                    if (ConfigService.UseAssessmentForSignal10) {
                        const m10: any = Fhir.Tools.GetOrCreateFlag(this.patient.flags, `${NitTools.ExcludeTrailingSlash(environment.nursItStructureDefinition)}/marks/mark_10`, true);
                        const m10_r: any = Fhir.Tools.GetOrCreateFlag(this.patient.flags, `${NitTools.ExcludeTrailingSlash(environment.nursItStructureDefinition)}/marks/mark_10_red`, true);
                        const m10_y: any = Fhir.Tools.GetOrCreateFlag(this.patient.flags, `${NitTools.ExcludeTrailingSlash(environment.nursItStructureDefinition)}/marks/mark_10_yellow`, true);
                        m10.code = 'true';
                        m10_r.code = 'false';
                        m10_y.code = 'false';
                    }

                    if (ConfigService.UseBarthelIndexForFlag5) {
                        const m5: any = Fhir.Tools.GetOrCreateFlag(this.patient.flags, `${NitTools.ExcludeTrailingSlash(environment.nursItStructureDefinition)}/marks/mark_5`, true);
                        const m5_r: any = Fhir.Tools.GetOrCreateFlag(this.patient.flags, `${NitTools.ExcludeTrailingSlash(environment.nursItStructureDefinition)}/marks/mark_5_red`, true);
                        const m5_y: any = Fhir.Tools.GetOrCreateFlag(this.patient.flags, `${NitTools.ExcludeTrailingSlash(environment.nursItStructureDefinition)}/marks/mark_5_yellow`, true);
                        m5.code = 'true';
                        m5_r.code = 'true';
                        m5_y.code = 'false';
                    }

                    bundleItems.push(this.patient.flags);
                }

                let bundle: any = this.fhirService.getBundle(bundleItems, HTTPVerb.put, BundleType.transaction);
                await this.fhirService.post(bundle, false);

                this.assessment_created = true;
                this.patient.latestAssessment = assessmentResponse;
                await this.analyzeService.analyse(this.patient, this.patient.latestAssessment, true, true);

                isAnalyzed = true;
                // maybe not needed:
                PatientService.AddQuestionnaireResponse(this.patient, this.patient.latestAssessment, true);

                if (ConfigService.UseAssessmentForSignal10) {
                    const m10 = this.patient.marks.find(o => o.index === 10);
                    if (m10) {
                        m10.red = false;
                        m10.yellow = false;
                        m10.checked = true;
                    }
                }

                if (ConfigService.UseBarthelIndexForFlag5) {
                    const m5 = this.patient.marks.find(o => o.index === 5);
                    if (m5) {
                        m5.red = true;
                        m5.yellow = false;
                        m5.checked = true;
                    }
                }

                this.notifier.notify(this.patient, this.patient.flags);

                RuntimeInfo.ShowInfo(this.i18n.tr('assessment_auto_generated'));

                // try to trigger the afterSave of the assessment
                /*try {
                    const assessmentView = new viewAssessment(this.i18n, this.router, this.taskQueue, this.notifier,
                        this.dialogService, this.analyzeService, this.patientService, this.dialogMessages, this.idLogikService);
                    assessmentView.questionnaire = assessmentQuestionnaire;
                    assessmentView.response = assessmentResponse;
                    await assessmentView.afterSave(assessmentView.response);
                }
                catch (e) {
                    if (ConfigService.Debug)
                        console.warn(e.message||e)
                } */
            }
        }
        //#endregion
        else this.notifier.notify(this.patient);

        if (['completed', 'amended'].indexOf(this.response.status) > -1 && !isAnalyzed && latestAssessment)
            await this.analyzeService.analyse(this.patient, latestAssessment, true, true);

        await super.afterSave(response);

        return Promise.resolve();
    }

    async beforeSave(): Promise<any> {
        await ConfigService.LoadConfigOverride(this.patient?.ward, this.patient);
        this.assessmentBackup = NitTools.Clone(this.patient.latestAssessment);
        await super.beforeSave();
        await super.recalcRisks(this.patient.latestAssessment, true);

        let dischargeQuestionnaire = QuestionnaireService.GetQuestionnaireDirect(this.qList.QDischargeManagementId);
        if (dischargeQuestionnaire) {
            let dischargeResponse = QuestionnaireService.GetLatestResponseOfType(this.patient, this.qList.QDischargeManagementId);
            if (!dischargeResponse) {
                dischargeResponse = Fhir.Tools.SubstituteDefaultQRSkeleton(this.patient, this.qList.QDischargeManagementId, QuestionnaireResponseStatus.inProgress);
            }

            const dischargeSetting = ConfigService.GetFormSettings('discharge');
            if (dischargeSetting && dischargeSetting.settings && dischargeSetting.settings["dischargeActiveWhen"]) {
                const [field, value] = dischargeSetting.settings["dischargeActiveWhen"].split('=');

                let releaseMgtItem = QuestionnaireResponse.GetResponseItemByLinkId(this.response, field);
                if (releaseMgtItem) {
                    let currentValue = QuestionnaireResponse.GetResponseItemValue(releaseMgtItem);
                    const isDischargeActive = String(currentValue) === String(value);

                    Fhir.Questionnaire.EnsureStructuredResponse(dischargeQuestionnaire, dischargeResponse);
                    let dischargeActiveElement = QuestionnaireResponse.GetResponseItemByLinkId(dischargeResponse, 'E_2', true);
                    if (dischargeActiveElement) {
                        dischargeActiveElement.answer = [{valueBoolean: isDischargeActive}];
                    }

                    this.bundleItems.push(dischargeResponse);
                }
            }

            if (dischargeResponse)
                this.patientService.addQuestionnaireResponse(this.patient, dischargeResponse, true);
        }
    }

    async headerSaveButtonClicked(status: string): Promise<undefined | void> {
        return super.headerSaveButtonClicked(status);
    }
}

export interface ICreateDefaultAssessmentOn {
    "field": string;
    "value": string;
}
